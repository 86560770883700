<template>
  <div class="my-15 text-center">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
