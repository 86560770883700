<template>
  <v-card
    id="base-card"
    :loading="loading"
    :color="customColor"
    :elevation="customElevation"
  >
    <router-link v-if="to" :to="to">
      <slot></slot>
    </router-link>
    <slot v-else></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customColor: {
      type: String,
      default: "card",
    },
    customElevation: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
#base-card {
  padding: 10px 0px;
  border-radius: 10px;
}

a {
  text-decoration: none;
  color: black !important;
}
</style>
