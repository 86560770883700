import { db } from "../../../firebase-init";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

export default {
  // Create a new event in the sessions collection
  async createEvent(_, payload) {
    const docRef = await addDoc(collection(db, "sessions"), {
      ...payload,
    });
    const event = {
      ...payload,
      id: docRef.id,
    };
    return event;
  },

  // Delete an event by id
  async deleteEvent(_, payload) {
    await deleteDoc(doc(db, "sessions", payload.id));
  },

  // Get event by ID
  async getEventById(_, payload) {
    const docSnapshot = await getDoc(doc(db, "sessions", payload));
    let event = "";
    if (docSnapshot.exists()) {
      event = docSnapshot.data();
      event.id = docSnapshot.id;
    }
    return event;
  },

  // Update an existing event
  async updateEvent(_, payload) {
    await updateDoc(doc(db, "sessions", payload.id), {
      ...payload.data,
    });
  },

  // Get sessions for a specific trainer
  async getTrainerSessions(_, payload) {
    let sessions = [];
    const q = query(
      collection(db, "sessions"),
      where("trainerId", "==", payload.trainerId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnapshot) => {
      const session = docSnapshot.data();
      session.id = docSnapshot.id;
      sessions.push(session);
    });
    return sessions;
  },

  // Load trainer availability and append it to sessions
  async loadTrainerAvailability(context, payload) {
    let sessions = payload.sessions;
    let trainerAvailability = [];

    trainerAvailability = await context.dispatch(
      "trainerAvailability/getTrainerAvailability",
      {
        trainerId: payload.trainerId,
      },
      { root: true }
    );

    if (trainerAvailability.length > 0) {
      trainerAvailability.forEach((timeframe) => {
        let session = {
          start: timeframe.startTime,
          end: timeframe.endTime,
          color: context.getters.availabilityColor,
        };
        sessions.push(session);
      });
    }

    return sessions;
  },

  // Load events for a specific user
  async loadUserEvents(context, payload) {
    let userEvents = [];
    const q = query(
      collection(db, "sessions"),
      where("userId", "==", payload.userId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((docSnapshot) => {
      const userEvent = docSnapshot.data();
      userEvent.id = docSnapshot.id;
      userEvents.push(userEvent);
    });

    userEvents.sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });

    context.commit("setSessionsUser", userEvents);
  },

  // Load events for a specific trainer
  async loadTrainerEvents(context, payload) {
    let sessions = [];
    const q = query(
      collection(db, "sessions"),
      where("trainerId", "==", payload.trainerId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((docSnapshot) => {
      const session = docSnapshot.data();
      session.id = docSnapshot.id;
      sessions.push(session);
    });

    if (payload.loadAvailability) {
      sessions = await context.dispatch("loadTrainerAvailability", {
        trainerId: payload.trainerId,
        sessions: sessions,
      });
    }

    context.commit("setSessionsTrainer", sessions);
  },

  // Set the state for the Add Event Dialog
  setAddEventDialog(context, payload) {
    context.commit("setAddEventDialog", payload);
  },
};
