import { db } from "../../../firebase-init";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

export default {
  // Add or update a session for a trainer
  async addOrUpdateSession(context, payload) {
    let trainer = context.rootGetters["trainers/currentUserTrainerProfile"];

    if (!trainer.sessions) {
      trainer.sessions = [];
    }

    let index = trainer.sessions.findIndex(
      (session) => session.id === payload.data.id
    );

    if (index >= 0) {
      trainer.sessions[index] = {
        ...payload.data,
      };
    } else {
      trainer.sessions.push({
        ...payload.data,
      });
    }

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: payload.trainerId,
        data: {
          ...trainer,
        },
      },
      {
        root: true,
      }
    );
  },

  // Get a session by its ID
  async getSessionById(_, payload) {
    let session = null;

    const sessionDoc = await getDoc(doc(db, "sessions", payload));
    if (sessionDoc.exists()) {
      session = {
        id: sessionDoc.id,
        ...sessionDoc.data(),
      };
    }

    return session;
  },

  // Remove a session
  async removeSession(context, payload) {
    let trainer = context.rootGetters["trainers/currentUserTrainerProfile"];
    let index = trainer.sessions.findIndex(
      (session) => session.id === payload.sessionId
    );
    let sessionToRemoveIsTrial = trainer.sessions[index]?.isTrial;

    if (
      trainer.sessions.find((session) => session.isTrial === true) &&
      !sessionToRemoveIsTrial
    ) {
      if (trainer.sessions.length === 2) {
        throw new Error(
          "A trainer must have at least one non-trial session active. You cannot remove the last session."
        );
      }
    } else {
      if (trainer.sessions.length === 1) {
        throw new Error(
          "A trainer must have at least one non-trial session active. You cannot remove the last session."
        );
      }
    }

    if (index >= 0) {
      trainer.sessions.splice(index, 1);
    } else {
      throw new Error("The session id provided does not exist.");
    }

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: payload.trainerId,
        data: {
          ...trainer,
        },
      },
      {
        root: true,
      }
    );
  },

  setAddOrUpdateSessionDialog(context, payload) {
    context.commit("setAddOrUpdateSessionDialog", payload);
  },

  setReportSessionDialog(context, payload) {
    context.commit("setReportSessionDialog", payload);
  },

  setViewSessionDialog(context, payload) {
    context.commit("setViewSessionDialog", payload);
  },

  // Verify if there are any non-active sessions for a user
  async verifyNonActiveSessionsByUserId(_, payload) {
    let newOrAcceptedSessionsExist = false;

    // Check if there are any sessions where trainerUserId matches the payload
    const trainerSessionQuery = query(
      collection(db, "sessions"),
      where("trainerUserId", "==", payload)
    );
    const trainerSessionSnapshot = await getDocs(trainerSessionQuery);
    trainerSessionSnapshot.forEach((docSnapshot) => {
      const session = docSnapshot.data();
      session.id = docSnapshot.id;
      const latestStatus =
        session.statusLogs[session.statusLogs.length - 1].status;
      if (latestStatus === "new" || latestStatus === "accepted") {
        newOrAcceptedSessionsExist = true;
      }
    });

    // Check if there are any sessions where userId matches the payload
    const userSessionQuery = query(
      collection(db, "sessions"),
      where("userId", "==", payload)
    );
    const userSessionSnapshot = await getDocs(userSessionQuery);
    userSessionSnapshot.forEach((docSnapshot) => {
      const session = docSnapshot.data();
      session.id = docSnapshot.id;
      const latestStatus =
        session.statusLogs[session.statusLogs.length - 1].status;
      if (latestStatus === "new" || latestStatus === "accepted") {
        newOrAcceptedSessionsExist = true;
      }
    });

    if (newOrAcceptedSessionsExist) {
      throw new Error(
        "You cannot delete your account as you have new or accepted sessions. Please cancel or complete them first."
      );
    }

    return !newOrAcceptedSessionsExist;
  },
};
