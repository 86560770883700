import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    autoLoginCompleted: false,
    isAuthenticated: false,
    isEmailVerified: false,
    isTrainer: false,
    isTrainerRegistrationApproved: false,
    userId: null,
  }),
  mutations,
  actions,
  getters,
};
