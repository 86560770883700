import { createStore } from "vuex";
import AuthModule from "./modules/auth/index.js";
import BlogpostsModule from "./modules/blogposts/index.js";
import CalendarModule from "./modules/calendar/index.js";
import DataModule from "./modules/data/index.js";
import EmailModule from "./modules/email/index.js";
import NotificationsModule from "./modules/notifications/index.js";
import PaymentsModule from "./modules/payments/index.js";
import ReviewsModule from "./modules/reviews/index.js";
import RoomModule from "./modules/room/index.js";
import SessionsModule from "./modules/sessions/index.js";
import TrainersModule from "./modules/trainers/index.js";
import TrainerAvailabilityModule from "./modules/trainer.availability/index.js";
import UsersModule from "./modules/users/index.js";
import WebsiteDataModule from "./modules/website.data/index.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthModule,
    blogposts: BlogpostsModule,
    calendar: CalendarModule,
    data: DataModule,
    email: EmailModule,
    notifications: NotificationsModule,
    payments: PaymentsModule,
    reviews: ReviewsModule,
    room: RoomModule,
    sessions: SessionsModule,
    trainerAvailability: TrainerAvailabilityModule,
    trainers: TrainersModule,
    users: UsersModule,
    websiteData: WebsiteDataModule,
  },
});
