export default {
  setNotifications(state, payload) {
    state.notifications = payload;
  },
  setNewMessageNotificationsCount(state, payload) {
    state.newMessageNotificationsCount = payload;
  },
  setSessionUpdateNotificationsCount(state, payload) {
    state.sessionUpdateNotificationsCount = payload;
  },
};
