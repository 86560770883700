<template>
  <v-container class="bottom-row-container text-body-2">
    <v-row justify="center">
      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="3"
        :align="platformNameAlignment"
        class="order-last order-md-first order-lg-first order-xl-first"
      >
        <v-icon color="white" size="small" class="mb-1 mr-1"
          >mdi-copyright</v-icon
        >
        {{ new Date().getFullYear() }} —
        <strong>NetworkTal</strong>
      </v-col>
      <v-col cols="12" md="6" lg="5" xl="4" :align="linkBarAlignment">
        <v-row>
          <v-col>
            <a href="/aboutus" class="text-decoration-none">
              <router-link class="pagelink" to="/aboutus">About Us</router-link>
            </a>
            <a href="/privacy-policy" class="text-decoration-none">
              <router-link class="pagelink" to="/privacy-policy">
                Privacy Policy
              </router-link>
            </a>
            <a href="/terms-and-conditions" class="text-decoration-none">
              <router-link class="pagelink" to="/terms-and-conditions">
                Terms and Conditions
              </router-link>
            </a>
            <a href="/contact" class="text-decoration-none">
              <router-link class="pagelink" to="/contact">Contact</router-link>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    linkBarAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "start";
        case "lg":
          return "start";
        case "xl":
          return "start";
        default:
          return "start";
      }
    },
    platformNameAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.bottom-row-container {
  font-size: 16px;
}
.pagelink {
  color: white;
  text-decoration: none;
  margin: 0px 10px 0px 10px;
}
.pagelink:hover {
  color: rgb(235, 235, 235);
}
</style>
