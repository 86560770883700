export default {
  addOrUpdateSessionDialog(state) {
    return state.addOrUpdateSessionDialog;
  },
  reportSessionDialog(state) {
    return state.reportSessionDialog;
  },
  viewSessionDialog(state) {
    return state.viewSessionDialog;
  },
};
