import { db } from "../../../firebase-init";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";

export default {
  // Add a review to Firestore
  async addReview(_, payload) {
    await addDoc(collection(db, "reviews"), payload);
  },

  // Get reviews by Trainer ID
  async getReviewsByTrainerId(context, payload) {
    let reviews = [];

    const q = query(
      collection(db, "reviews"),
      where("trainerId", "==", payload)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 0) {
      // No reviews found
      // console.warn('Warning: No reviews have been found with the trainer id:', payload);
    } else {
      querySnapshot.forEach((docSnapshot) => {
        reviews.push({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      });
      // console.log('Trainer reviews:', JSON.stringify(reviews));
    }

    return reviews;
  },

  // Set the trainer rating info based on reviews
  async setTrainerRatingInfo(context, payload) {
    // Get the reviews by trainer ID
    const reviews = await context.dispatch("getReviewsByTrainerId", payload);

    // Calculate the average rating
    let trainerAverageReviewsRating = 0;
    if (reviews.length > 0) {
      let totalRating = 0;
      reviews.forEach((review) => {
        totalRating += review.rating;
      });

      // Calculate the average rating with 1 decimal
      trainerAverageReviewsRating =
        Math.round((totalRating / reviews.length) * 10) / 10;
    }

    // Get the trainer profile
    const trainerProfile = await context.dispatch(
      "trainers/getTrainerById",
      { id: payload },
      { root: true }
    );

    // Set the rating info in the trainer profile
    trainerProfile.reviewsInfo = {
      averageReviewsRating: trainerAverageReviewsRating,
      reviewsCount: reviews.length,
    };

    // Update the trainer profile
    const trainerId = trainerProfile.id;
    delete trainerProfile.id;

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: trainerId,
        data: {
          ...trainerProfile,
        },
      },
      { root: true }
    );

    // console.log('Reviews:', JSON.stringify(reviews));
    // console.log('Reviews length:', reviews.length);
    // console.log('Trainer average rating:', trainerAverageReviewsRating);
    // console.log('Trainer profile:', JSON.stringify(trainerProfile));
  },

  // Set the trainer review dialog
  setTrainerReviewDialog(context, payload) {
    context.commit("setTrainerReviewDialog", payload);
  },
};
