<template>
  <div class="my-5 text-subtitle-1">
    <v-alert v-if="successMessage" type="success">
      {{ successMessage }}
    </v-alert>
    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    successMessage: {
      required: false,
      default: "",
    },
    errorMessage: {
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped></style>
