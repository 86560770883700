import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import mitt from "mitt";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Handles UTC time conversions
import timezone from "dayjs/plugin/timezone"; // Handles timezone conversions
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Handles isSameOrAfter method
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Handles isSameOrBefore method

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Set a default timezone
dayjs.tz.setDefault("UTC");

// Import global components
import BaseButton from "./components/ui/basebutton.vue";
import BaseCard from "./components/ui/basecard.vue";
import BaseDialog from "./components/ui/basedialog.vue";
import BaseDialogConfirm from "./components/ui/basedialogconfirm.vue";
import BaseErrorSuccessMessage from "./components/ui/baseerrorsuccessmessage.vue";
import BaseIcon from "./components/ui/baseicon.vue";
import BaseLoader from "./components/ui/baseloader.vue";
import BaseNotificationCard from "./components/ui/basenotificationcard.vue";
import BaseTooltipInfo from "./components/ui/basetooltipinfo.vue";
import VideoCard from "./components/ui/videocard.vue";

const app = createApp(App);
const eventBus = mitt();

// Register global components
app.component("base-button", BaseButton);
app.component("base-card", BaseCard);
app.component("base-dialog", BaseDialog);
app.component("base-dialog-confirm", BaseDialogConfirm);
app.component("base-error-success-message", BaseErrorSuccessMessage);
app.component("base-icon", BaseIcon);
app.component("base-loader", BaseLoader);
app.component("base-notification-card", BaseNotificationCard);
app.component("base-tooltip-info", BaseTooltipInfo);
app.component("video-card", VideoCard);

app.provide("eventBus", eventBus);
app.provide("dayjs", dayjs);

app.use(router).use(store).use(vuetify).mount("#app");
