<template>
  <v-footer class="text-white text-center" color="colorSpecial5">
    <v-container fluid class="pt-5">
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" lg="3" xl="2" class="">
          <app-pages></app-pages>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="3" xl="2" class="">
          <app-info></app-info>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="3" class="">
          <app-icons></app-icons>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <bottom-row></bottom-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import AppPages from "./apppages.vue";
import AppInfo from "./appinfo.vue";
import AppIcons from "./appicons.vue";
import BottomRow from "./bottomrow.vue";

export default {
  components: {
    AppPages,
    AppInfo,
    AppIcons,
    BottomRow,
  },
  computed: {
    ...mapGetters({
      icons: "websiteData/icons",
    }),
  },
};
</script>

<style scoped></style>
