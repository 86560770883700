export default {
  activeRoomId(state) {
    return state.activeRoomId;
  },
  activeRoomName(state) {
    return state.activeRoomName;
  },
  activeUsers(state) {
    return state.activeUsers;
  },
  roomMessages(state) {
    return state.roomMessages;
  },
  users(state) {
    return state.users;
  },
};
