<template>
  <v-btn
    class="ma-1 font-weight-regular text-white base-button"
    :color="customColor"
    elevation="0"
    :min-width="customWidth"
    :height="customHeight"
    size="large"
    :to="toPath"
    v-bind="$props"
    :disabled="disabled"
    :style="'font-size: ' + customFontSize + ';'"
    :variant="customVariant"
    :loading="customLoading"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    toPath: {
      type: String,
      required: false,
    },
    customColor: {
      type: String,
      required: false,
      default: "button",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customWidth: {
      type: String,
      required: false,
      default: "100px",
    },
    customHeight: {
      type: String,
      required: false,
      default: "40px",
    },
    customFontSize: {
      type: String,
      required: false,
      default: "14px",
    },
    customVariant: {
      type: String,
      required: false,
      default: "elevated",
    },
    customLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.base-button {
  border-radius: 30px;
  font-weight: 450;
}
</style>
