import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    certificationPhotoDefault: require("@/assets/default/trainer-certification-default-02.png"),
    confirmDialog: false,
    currency: null,
    currencyDefault: {
      symbol: "$",
      name: "US Dollar",
      code: "USD",
      name_plural: "US dollars",
    },
    drawer: false,
    icons: [
      "mdi-facebook",
      "mdi-twitter",
      "mdi-linkedin",
      "mdi-instagram",
      "mdi-youtube",
    ],
    socialMediaProfiles: {
      facebook: "https://www.facebook.com/profile.php?id=100090834973914",
      x: "https://x.com/networktalcom",
      linkedin: "https://www.linkedin.com/company/networktal",
      instagram: "https://www.instagram.com/networktal/",
      youtube: "https://www.youtube.com/channel/UC6qYkcwsFnZ3I4vXMBrCzPg",
    },
    timezone: "UTC",
    userPhotoDefault: require("@/assets/default/user-account-default-01.png"),
  }),
  mutations,
  actions,
  getters,
};
