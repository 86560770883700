import { db } from "../../../firebase-init";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export default {
  // Create or update a user in Firestore
  async createOrUpdateUser(_, payload) {
    await setDoc(doc(db, "users", payload.id), payload.data);
  },

  // Retrieve user by userId from Firestore
  async getUserByUserId(context, payload) {
    let userDetails = null;
    const q = query(collection(db, "users"), where("userId", "==", payload));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 0) {
      // console.warn(`No user found with user id: ${payload}`);
    } else if (querySnapshot.size === 1) {
      querySnapshot.forEach((doc) => {
        userDetails = {
          id: doc.id,
          ...doc.data(),
        };
      });
    } else {
      // console.warn(`Multiple users found with user id: ${payload}`);
    }

    // Check if the current user matches the found user and dispatch "setCurrentUser"
    let currentUserId = context.rootGetters["auth/userId"];
    if (userDetails && currentUserId === userDetails.userId) {
      context.dispatch("setCurrentUser", userDetails);
    }

    return userDetails;
  },

  // Set the current user in Vuex state
  setCurrentUser(context, payload) {
    context.commit("setCurrentUser", payload);
  },
};
