export default {
  setEmailVerified(state, payload) {
    state.isEmailVerified = payload;
  },
  setFirebaseUserProfile(state, payload) {
    state.firebaseUserProfile = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setAutoLoginCompleted(state, payload) {
    state.autoLoginCompleted = payload;
  },
  setIsTrainer(state, payload) {
    state.isTrainer = payload;
  },
  setIsTrainerRegistrationApproved(state, payload) {
    state.isTrainerRegistrationApproved = payload;
  },
  setUser(state, payload) {
    state.token = payload.token;
    state.userId = payload.userId;
    state.didAutoLogout = false;
  },
  setUserId(state, payload) {
    state.userId = payload;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
};
