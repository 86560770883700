export default {
  setConfirmDialog(context, payload) {
    context.commit("setConfirmDialog", payload);
  },
  setDrawer(context, payload) {
    // console.log('setDrawer: ' + payload)
    context.commit("setDrawer", payload);
  },
  setUserCurrencyOrDefault(context) {
    let currency = context.getters["currencyDefault"];
    let currentUser = context.rootGetters["users/currentUser"];

    if (currentUser !== null) {
      currency = currentUser.currency;
    }

    context.commit("setCurrency", currency);
  },
  setUserTimezone(context) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let currentUser = context.rootGetters["users/currentUser"];

    if (currentUser !== null) {
      timezone = currentUser.timezone;
    }

    context.commit("setTimezone", timezone);
  },
};
