import { db } from "../../../firebase-init";
import { addDoc, collection } from "firebase/firestore";
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";

function convertUTCDateTimeToOtherTimezone(utcDateTime, timezone) {
  let dateInCustomTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
    utcDateTime,
    timezone
  ).format("dddd, MMMM D, YYYY, HH:mm");

  return dateInCustomTimezone;
}

function generateHTMLTemplate(
  emailContent,
  trainerOrUserSessionURL,
  buttonTitle
) {
  let emailTemplateHTML = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Simple Transactional Email</title>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
      />
      <style media="all" type="text/css">
        @media all {
          .btn-primary table td:hover {
            background-color: #ff2e63 !important;
          }
  
          .btn-primary a:hover {
            background-color: #ff2e63 !important;
            border-color: #ff2e63 !important;
          }
        }
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
  
          .wrapper {
            padding: 8px !important;
          }
  
          .content {
            padding: 0 !important;
          }
  
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            width: 100% !important;
          }
  
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
  
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
  
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
        @media all {
          .ExternalClass {
            width: 100%;
          }
  
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%;
          }
  
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
  
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
      </style>
    </head>
    <body
      style="
        font-family: Roboto, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        line-height: 1.3;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        background-color: #f4f5f6;
        margin: 0;
        padding: 0;
      "
    >
      <table
        role="presentation"
        border="0"
        cellpadding="0"
        cellspacing="0"
        class="body"
        style="
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          background-color: #f4f5f6;
          width: 100%;
        "
        width="100%"
        bgcolor="#f4f5f6"
      >
        <tr>
          <td
            style="
              font-family: Roboto, sans-serif;
              font-size: 16px;
              vertical-align: top;
            "
            valign="top"
          >
            &nbsp;
          </td>
          <td
            class="container"
            style="
              font-family: Roboto, sans-serif;
              font-size: 16px;
              vertical-align: top;
              max-width: 600px;
              padding: 0;
              padding-top: 24px;
              width: 600px;
              margin: 0 auto;
            "
            width="600"
            valign="top"
          >
            <div
              class="content"
              style="
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 600px;
                padding: 0;
              "
            >
              <!-- START CENTERED WHITE CONTAINER -->
              <span
                class="preheader"
                style="
                  color: transparent;
                  display: none;
                  height: 0;
                  max-height: 0;
                  max-width: 0;
                  opacity: 0;
                  overflow: hidden;
                  mso-hide: all;
                  visibility: hidden;
                  width: 0;
                "
                >Welcome to NetworkTal!</span
              >
              <table
                role="presentation"
                border="0"
                cellpadding="0"
                cellspacing="0"
                class="main"
                style="
                  border-collapse: separate;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  background: #ffffff;
                  border: 1px solid #eaebed;
                  border-radius: 16px;
                  width: 100%;
                "
                width="100%"
              >
                <!-- Logo Area -->
                <tr>
                  <td
                    align="center"
                    valign="top"
                    style="padding-top: 30px; padding-bottom: 10px"
                  >
                    <a href="https://www.networktal.com" target="_blank">
                      <img
                        src="https://ntstorage01.blob.core.windows.net/social-media-icons/networktal-logo-01.png"
                        alt="Logo"
                        class="logo"
                        width="50"
                      />
                    </a>
                    <p
                      style="
                        font-family: Roboto, sans-serif;
                        font-size: 16px;
                        color: #393e46;
                      "
                    >
                      <strong>NETWORKTAL</strong>
                    </p>
                  </td>
                </tr>
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td
                    class="wrapper"
                    style="
                      font-family: Roboto, sans-serif;
                      color: #393e46;
                      font-size: 16px;
                      vertical-align: top;
                      box-sizing: border-box;
                      padding: 24px;
                    "
                    valign="top"
                  >
                    ${emailContent}
                    <table
                      role="presentation"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="btn btn-primary"
                      style="
                        border-collapse: separate;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        box-sizing: border-box;
                        width: 100%;
                        min-width: 100%;
                      "
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style="
                              font-family: Roboto, sans-serif;
                              font-size: 16px;
                              vertical-align: top;
                              padding-bottom: 16px;
                              padding-top: 24px;
                            "
                            valign="top"
                          >
                            <table
                              role="presentation"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border-collapse: separate;
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                width: auto;
                                margin: 0 auto;
                                margin-top: 15px;
                                margin-bottom: 24px;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style="
                                      font-family: Roboto, sans-serif;
                                      font-size: 16px;
                                      vertical-align: top;
                                      border-radius: 4px;
                                      text-align: center;
                                      background-color: #00adb5;
                                    "
                                    valign="top"
                                    align="center"
                                    bgcolor="#00ADB5"
                                  >
                                    <a
                                      href="${trainerOrUserSessionURL}"
                                      target="_blank"
                                      style="
                                        border: solid 2px #00adb5;
                                        border-radius: 4px;
                                        box-sizing: border-box;
                                        cursor: pointer;
                                        display: inline-block;
                                        font-size: 16px;
                                        font-weight: bold;
                                        margin: 0;
                                        padding: 12px 24px;
                                        text-decoration: none;
                                        text-transform: capitalize;
                                        background-color: #00adb5;
                                        border-color: #00adb5;
                                        color: #ffffff;
                                      "
                                      >${buttonTitle}</a
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
  
                <!-- END MAIN CONTENT AREA -->
              </table>
  
              <!-- START FOOTER -->
              <div
                class="footer"
                style="
                  clear: both;
                  margin-top: 50px;
                  text-align: center;
                  width: 100%;
                "
              >
                <table
                  role="presentation"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  style="
                    border-collapse: separate;
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    width: 100%;
                  "
                  width="100%"
                >
                  <tr style="height: 60px">
                    <td align="center" valign="top" class="footer">
                      <div class="social-icons">
                        <a href="https://www.linkedin.com/company/networktal"
                          ><img
                            src="https://ntstorage01.blob.core.windows.net/social-media-icons/linkedin-icon-01.png"
                            alt="LinkedIn"
                            width="25"
                            style="margin: 0 10px"
                        /></a>
                        <a
                          href="https://www.facebook.com/profile.php?id=100090834973914"
                          ><img
                            src="https://ntstorage01.blob.core.windows.net/social-media-icons/facebook-icon.png"
                            alt="Facebook"
                            width="25"
                            style="margin: 0 10px"
                        /></a>
                        <a href="https://www.instagram.com/networktal/"
                          ><img
                            src="https://ntstorage01.blob.core.windows.net/social-media-icons/instagram-icon.png"
                            alt="Instagram"
                            width="25"
                            style="margin: 0 10px"
                        /></a>
                        <a href="https://x.com/networktalcom"
                          ><img
                            src="https://ntstorage01.blob.core.windows.net/social-media-icons/x-icon.png"
                            alt="X"
                            width="25"
                            style="margin: 0 10px"
                        /></a>
                        <a href="https://www.youtube.com/channel/UC6qYkcwsFnZ3I4vXMBrCzPg"
                          ><img
                            src="https://ntstorage01.blob.core.windows.net/social-media-icons/youtube-icon.png"
                            alt="X"
                            width="25"
                            style="margin: 0 10px"
                        /></a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="content-block"
                      style="
                        font-family: Roboto, sans-serif;
                        vertical-align: top;
                        color: #393e46;
                        font-size: 12px;
                        text-align: center;
                        padding-bottom: 50px;
                      "
                      valign="top"
                      align="center"
                    >
                      <span
                        class="apple-link"
                        style="
                          color: #393e46;
                          font-size: 12px;
                          text-align: center;
                        "
                        >NetworkTal, LLC, 131 Continental Dr, Suite 305,
                        19713 Newark, Delaware, United States of America
                      </span>
                      <br />
                      Visit us on
                      <a
                        href="https://www.networktal.com"
                        style="
                          text-decoration: underline;
                          color: #393e46;
                          font-size: 12px;
                          text-align: center;
                        "
                        >www.networktal.com</a
                      >
                    </td>
                  </tr>
                </table>
              </div>
  
              <!-- END FOOTER -->
  
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td
            style="
              font-family: Roboto, sans-serif;
              font-size: 16px;
              vertical-align: top;
            "
            valign="top"
          >
            &nbsp;
          </td>
        </tr>
      </table>
    </body>
  </html>  
  `;
  return emailTemplateHTML;
}

function generateSessionReportedEmailTemplate(
  reporterDisplayName,
  reporteeDisplayName,
  sessionURL
) {
  let emailTemplateHTML = generateHTMLTemplate(
    `
                  <p style="margin-bottom: 40px">
                    Hi ${reporteeDisplayName},
                  </p>
                  <p>
                    ${reporterDisplayName} reported a problem with the session.
                  </p>
                  <strong>What happens next?</strong>
                  </p>
                  <ul>
                    <li>
                      Our team will review the reported problem and take
                      necessary actions.
                    </li>
                    <li>
                      We will reach out to you if we need more information.
                    </li>
                    <li>
                      The session remains on hold until the matter is resolved.
                    </li>
                    <li>
                      You will be notified with the progress of it via email.
                    </li>
                  </ul>
                  <strong>
                    Need assistance?
                  </strong>
                  <p>
                    Our support team is always ready to assist you. Contact us
                    at
                    <a href="mailto:support@networktal.com"
                      >support@networktal.com</a
                    >
                    for any inquiries.
                  </p>
    `,
    sessionURL,
    "View Session"
  );
  return emailTemplateHTML;
}

async function getTrainerCommunicationChannelHTMLDetails(
  context,
  trainerId,
  communicationToolId
) {
  let trainerProfile = await context.dispatch(
    "trainers/getTrainerById",
    {
      id: trainerId,
    },
    {
      root: true,
    }
  );

  let selectedCommunicationTool = trainerProfile.communicationTools.find(
    (tool) => tool.id === communicationToolId
  );

  let selectedCommunicationToolHTMLDetails = ``;

  if (selectedCommunicationTool.name === "Zoom") {
    selectedCommunicationToolHTMLDetails = `
    <li>
      Communication channel: <strong>${selectedCommunicationTool.name}</strong> 
    </li>
    <li>
      Zoom meeting link: ${selectedCommunicationTool.toolId.meetingURL}
    </li>
    <li>
      Zoom meeting Id: <strong>${selectedCommunicationTool.toolId.personalMeetingId}</strong>
    </li>
    <li>
      Zoom meeting passcode: <strong>${selectedCommunicationTool.toolId.passcode}</strong>
    </li>
    `;
  } else {
    selectedCommunicationToolHTMLDetails = `
    <li>
      Communication channel: <strong>${selectedCommunicationTool.name}</strong> 
    </li>
    <li>
      ${selectedCommunicationTool.name} Id: <strong>${selectedCommunicationTool.toolId}</strong>
    </li>
    `;
  }

  return selectedCommunicationToolHTMLDetails;
}

// User email template for accepted, declined and cancelled session requests
async function generateUserEmailTemplate(context, payload, action) {
  let selectedCommunicationToolHTMLDetails =
    await getTrainerCommunicationChannelHTMLDetails(
      context,
      payload.trainerId,
      payload.communicationToolId
    );

  let whatIsNextHTML = "";

  if (action === "accepted") {
    whatIsNextHTML = `
    <p>
    <strong>What happens next?</strong>
    </p>
    <ul>
      <li>Prepare for your session with the trainer</li>
      <li>Review the selected communication channel details and ensure you can access it at the scheduled time</li>
      <li>Join the session at the scheduled time</li>
      <li>Click the button below to view and manage your sessions</li>
    </ul>
  `;
  } else if (action === "declined") {
    whatIsNextHTML = `
    <p>
    <strong>What happens next?</strong>
    </p>
    <ul>
      <li>You can contact the trainer via message to discuss the options</li>
      <li>Find another trainer on the platform and book a new session</li>
      <li>Click the button below to view and manage your sessions</li>
    </ul>
    `;
  } else if (action === "cancelled") {
    whatIsNextHTML = `
    <p>
    <strong>What happens next?</strong>
    </p>
    <ul>
      <li>You can contact the trainer via message to discuss the options</li>
      <li>You will receive a refund for the cancelled session</li>
      <li>Find another trainer on the platform and book a new session</li>
      <li>Click the button below to view and manage your sessions</li>
    </ul>
    `;
  }

  let sessionOrSessionRequest =
    action === "cancelled" ? "session" : "session request";
  let userLocalStartTime = convertUTCDateTimeToOtherTimezone(
    payload.start,
    payload.userTimezone
  );
  let userLocalEndTime = convertUTCDateTimeToOtherTimezone(
    payload.end,
    payload.userTimezone
  );

  let emailTemplateHTML = generateHTMLTemplate(
    `
                    <p style="margin-bottom: 40px">
                      Hi ${payload.userDisplayName},
                    </p>
                    <p>
                      Your ${sessionOrSessionRequest} has been
                      <strong>${action}</strong> by the trainer.
                    </p>
                    <p>
                      <strong> Session information </strong>
                    </p>
                    <ul>
                      <li>Trainer name: <strong>${payload.trainerDisplayName}</strong></li>
                      <li>Title: <strong>${payload.sessionTitle}</strong></li>
                      <li>Start time: <strong>${userLocalStartTime}</strong></li>
                      <li>End time: <strong>${userLocalEndTime}</strong></li>
                      <li>Timezone: <strong>${payload.userTimezone}</strong></li>
                      ${selectedCommunicationToolHTMLDetails}
                    </ul>
                    ${whatIsNextHTML}
      `,
    `${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/settings/user/mysessions/${payload.id}`,
    "View Session"
  );
  return emailTemplateHTML;
}

// Trainer email template for completed and cancelled sessions
async function generateTrainerEmailTemplate(context, payload, action) {
  let selectedCommunicationToolHTMLDetails =
    await getTrainerCommunicationChannelHTMLDetails(
      context,
      payload.trainerId,
      payload.communicationToolId
    );

  let whatIsNextHTML = "";

  if (action === "completed") {
    whatIsNextHTML = `
    <p>
    <strong>What happens next?</strong>
    </p>
    <ul>
      <li>The session amount will be transferred to your payment account</li>
      <li>Click the button below to view and manage your sessions</li>
    </ul>
  `;
  } else if (action === "cancelled") {
    whatIsNextHTML = `
    <p>
    <strong>What happens next?</strong>
    </p>
    <ul>
      <li>You can contact the user via message to discuss the options</li>
      <li>Click the button below to view and manage your sessions</li>
    </ul>
    `;
  }

  let cancelledOrCompletedText =
    action === "cancelled"
      ? "has <strong>cancelled</strong> a session with you"
      : "has marked as <strong>completed</strong> a session with you";
  let trainerLocalStartTime = convertUTCDateTimeToOtherTimezone(
    payload.start,
    payload.trainerTimezone
  );
  let trainerLocalEndTime = convertUTCDateTimeToOtherTimezone(
    payload.end,
    payload.trainerTimezone
  );

  let emailTemplateHTML = generateHTMLTemplate(
    `
                    <p style="margin-bottom: 40px">
                      Hi ${payload.trainerDisplayName},
                    </p>
                    <p>${payload.userDisplayName} ${cancelledOrCompletedText}.</p>
                    <p>
                      <strong> Session information </strong>
                    </p>
                    <ul>
                      <li>User name: <strong>${payload.userDisplayName}</strong></li>
                      <li>Title: <strong>${payload.sessionTitle}</strong></li>
                      <li>Start time: <strong>${trainerLocalStartTime}</strong></li>
                      <li>End time: <strong>${trainerLocalEndTime}</strong></li>
                      <li>Timezone: <strong>${payload.trainerTimezone}</strong></li>
                      ${selectedCommunicationToolHTMLDetails}
                    </ul>
                    ${whatIsNextHTML}
      `,
    `${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/settings/trainer/mysessions/${payload.id}`,
    "View Session"
  );
  return emailTemplateHTML;
}

// Trainer email template for rescheduled sessions - RESCHEDULE DISABLED FOR NOW
function generateTrainerEmailTemplateReschedule(context, payload) {
  let trainerLocalStartTimeOld = convertUTCDateTimeToOtherTimezone(
    payload.statusLogs[payload.statusLogs.length - 1].details.currentStart,
    payload.trainerTimezone
  );
  let trainerLocalEndTimeOld = convertUTCDateTimeToOtherTimezone(
    payload.statusLogs[payload.statusLogs.length - 1].details.currentEnd,
    payload.trainerTimezone
  );

  let trainerLocalStartTimeNew = convertUTCDateTimeToOtherTimezone(
    payload.statusLogs[payload.statusLogs.length - 1].details.newStart,
    payload.trainerTimezone
  );

  let trainerLocalEndTimeNew = convertUTCDateTimeToOtherTimezone(
    payload.statusLogs[payload.statusLogs.length - 1].details.newEnd,
    payload.trainerTimezone
  );

  let emailTemplateHTML = `
      <body>
      <p style="font-family: sans-serif; font-size: 16px; margin: 0 0 20px 0;">
        Hi ${payload.trainerDisplayName},
      </p>
      <p style="font-family: sans-serif; font-size: 16px;">
        <strong>${payload.userDisplayName}</strong> has sent you a request to reschedule a session with you.
      </p>
      <p style="font-family: sans-serif; font-size: 16px;">
        Session details:
      </p>
      <ul style="font-family: sans-serif; font-size: 16px; margin-bottom: 20px;">
        <li>User name: <strong>${payload.userDisplayName}<strong></li>
        <li>Old session start time: ${trainerLocalStartTimeOld}</li>
        <li>Old session end time: ${trainerLocalEndTimeOld}</li>
        <li>New session start time: ${trainerLocalStartTimeNew}</li>
        <li>New session end time: ${trainerLocalEndTimeNew}</li>
      </ul>
      <p style="font-family: sans-serif; font-size: 16px;">
        Go to your 
        <a href="${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/settings/trainer/mysessions" style="color: #337ab7; text-decoration: none;">account</a> 
        to accept or decline the rescheduled session.
      </p>
      <p style="font-family: sans-serif; font-size: 16px;">
        With best regards,
        <br>
        NetworkTal Team
      </p>
      </body>
      `;
  return emailTemplateHTML;
}

export default {
  async sendEmail(_, payload) {
    try {
      await addDoc(collection(db, "mail"), {
        to: payload.to,
        message: {
          subject: payload.subject,
          html: payload.html,
        },
      });
    } catch (error) {
      // Handle the error if necessary
      // console.error('Error sending email:', error);
    }
  },

  async sendSessionAcceptedEmailToUser(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.userEmail,
      subject: `Your session request has been accepted by ${payload.trainerDisplayName}`,
      html: await generateUserEmailTemplate(context, payload, "accepted"),
    });
  },

  async sendSessionDeclinedEmailToUser(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.userEmail,
      subject: `Your session request has been declined by ${payload.trainerDisplayName}`,
      html: await generateUserEmailTemplate(context, payload, "declined"),
    });
  },

  async sendSessionCancelledEmailToUser(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.userEmail,
      subject: `Your session request has been cancelled by ${payload.trainerDisplayName}`,
      html: await generateUserEmailTemplate(context, payload, "cancelled"),
    });
  },

  async sendSessionCompletedEmailToTrainer(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.trainerEmail,
      subject: `Your session with ${payload.userDisplayName} has been completed`,
      html: await generateTrainerEmailTemplate(context, payload, "completed"),
    });
  },

  async sendSessionCancelledEmailToTrainer(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.trainerEmail,
      subject: `Your session with ${payload.userDisplayName} has been cancelled`,
      html: await generateTrainerEmailTemplate(context, payload, "cancelled"),
    });
  },

  async sendSessionReportedEmailToTrainer(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.trainerEmail,
      subject: `${payload.userDisplayName} reported a problem with the session`,
      html: await generateSessionReportedEmailTemplate(
        payload.userDisplayName,
        payload.trainerDisplayName,
        `${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/settings/trainer/mysessions/${payload.id}`
      ),
    });
  },

  async sendSessionReportedEmailToUser(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.userEmail,
      subject: `${payload.trainerDisplayName} reported a problem with the session`,
      html: await generateSessionReportedEmailTemplate(
        payload.trainerDisplayName,
        payload.userDisplayName,
        `${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/settings/user/mysessions/${payload.id}`
      ),
    });
  },

  async sendSessionRescheduledEmailToTrainer(context, payload) {
    await context.dispatch("sendEmail", {
      to: payload.trainerEmail,
      subject: `New session reschedule request from ${payload.userDisplayName}`,
      html: await generateTrainerEmailTemplateReschedule(context, payload),
    });
  },

  async sendNewUserWelcomeEmail(context, payload) {
    let htmlEmail = generateHTMLTemplate(
      `<p>Hello ${payload.userDisplayName},</p>
    <br/>
    <p>
      Welcome to <strong>NetworkTal</strong>, we are excited to
      have you on board!
    </p>
    <p style="font-weight: bold; padding-top: 10px">
      What is next?
    </p>
    <ul>
      <li>
        <strong>Explore:</strong> Dive into our diverse range of
        skills categories and find the perfect trainer to guide
        your learning journey.
      </li>
      <li>
        <strong>Connect:</strong> Engage with expert trainers.
      </li>
      <li>
        <strong>Learn & Grow:</strong> Start your sessions and
        track your progress with our easy-to-use platform.
      </li>
    </ul>
        <p style="font-weight: bold; padding-top: 10px">
      Have Skills to Share? Become a Trainer!
    </p>
    <ul>
      <li>
        <strong>Empower Others:</strong> Share your expertise and
        help others in their learning journey.
      </li>
      <li>
        <strong>Earn Extra Income:</strong> Monetize your skills
        by setting your schedule and rates.
      </li>
      <li>
        <strong>Join a Community of Experts:</strong> Join a
        Community of Experts: Gain recognition and build your
        professional network.
      </li>
    </ul>
    <p style="font-weight: bold; padding-top: 10px">
      Need assistance?
    </p>
    <p>
      Our support team is always ready to assist you. Contact us
      at
      <a href="mailto:support@networktal.com"
        >support@networktal.com</a
      >
      for any inquiries.
    </p>`,
      `${process.env.VUE_APP_NETWORKTAL_PLATFORM_URL}/trainers`,
      "Find Trainer"
    );

    await context.dispatch("sendEmail", {
      to: payload.userEmail,
      subject: `Welcome to NetworkTal! Book your first session now`,
      html: htmlEmail,
    });
  },
};
