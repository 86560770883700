<template>
  <v-toolbar-title>
    <a href="/" class="text-decoration-none">
      <router-link to="/" class="app-bar-name">NetworkTal</router-link>
    </a>
  </v-toolbar-title>
</template>

<style scoped>
.app-bar-name {
  text-decoration: none;
  text-align: center;
  color: white;
}
</style>
