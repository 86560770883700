import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    activeRoomId: null,
    activeRoomName: null,
    activeUsers: [],
    users: [],
    roomMessages: [],
  }),
  mutations,
  actions,
  getters,
};
