export default {
  notifications(state) {
    return state.notifications;
  },
  newMessageNotificationsCount(state) {
    return state.newMessageNotificationsCount;
  },
  sessionUpdateNotificationsCount(state) {
    return state.sessionUpdateNotificationsCount;
  },
};
