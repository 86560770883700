<template>
  <iframe
    :src="url"
    frameborder="0"
    width="100%"
    height="100%"
    allowfullscreen
    loading="lazy"
  ></iframe>
  <!-- <iframe
        :src="url"
        frameborder="0"
        width="100%"
        height="100%"
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
        allowfullscreen
        loading="lazy"></iframe> -->
</template>

<script>
export default {
  props: {
    src: {
      required: true,
    },
  },
  data() {
    return {
      result: "",
      valid: false,
      url: "",
      videos: [
        {
          // eslint-disable-next-line no-useless-escape
          reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
          url: "https://www.youtube.com/embed/$5",
          params: {
            "picture-in-picture": 1,
            "encrypted-media": 1,
            accelerometer: 1,
            gyroscope: 1,
            autoplay: 0,
          },
        },
        {
          reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
          url: "https://player.vimeo.com/video/$1",
          params: {
            title: 0,
            byline: 0,
            portrait: 0,
          },
        },
      ],
    };
  },
  watch: {
    src() {
      this.parse();
    },
  },
  methods: {
    parse() {
      if (this.src) {
        for (let i = 0; i < this.videos.length; i++) {
          const v = this.videos[i];
          var m = v.reg.exec(this.src);
          if (m) {
            var params = Object.assign({}, v.params, this.params);
            var query = Object.keys(params)
              .map((key) => key + "=" + params[key])
              .join("&");
            var and = v.url.indexOf("?") >= 0 ? "&" : "?";
            this.url = this.src.replace(v.reg, v.url) + and + query;
            this.valid = true;
            return;
          }
        }
      }
      this.valid = false;
    },
  },
  mounted() {
    this.parse();
  },
};
</script>

<style scoped></style>
