<template>
  <v-tooltip
    location="bottom"
    color="primary"
    max-width="300px"
    :disabled="disabled"
    open-on-click
  >
    <template v-slot:activator="{ props }">
      <span v-bind="props">
        <v-icon v-if="!custom" size="20">mdi-information-outline</v-icon>
        <slot v-else></slot>
      </span>
    </template>
    <span v-html="text"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      default: "Add Information",
    },
    custom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
