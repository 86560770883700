<template>
  <div class="ma-0 pa-0">
    <router-link
      class="font-weight-medium ma-0 pa-0"
      to="/becometrainer/register"
      style="text-decoration: none"
    >
      <v-sheet
        color="#fed757"
        class="ma-0 pa-0 pt-15"
        style="position: sticky; z-index: 2; top: 0px"
      >
        <div
          class="text-subtitle-2 font-weight-regular pt-2 ma-0 pa-0"
          align="center"
          style="color: rgb(var(--v-theme-textPrimary)); min-height: 35px"
        >
          <v-icon class="mx-2">mdi-bullhorn-outline</v-icon>
          Trainer registration is now open, become a trainer now!
        </div>
      </v-sheet>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
