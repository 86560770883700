import { db } from "../../../firebase-init";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";

export default {
  // Add availability for a trainer
  async addAvailability(context, payload) {
    try {
      await addDoc(collection(db, "trainer_availability"), {
        trainerId: payload.trainerId,
        startTime: payload.startTime,
        endTime: payload.endTime,
      });
    } catch (error) {
      // console.error("Error adding availability:", error);
    }
  },

  // Fetch availability for a specific trainer
  async getTrainerAvailability(context, payload) {
    const trainerAvailability = [];

    try {
      const q = query(
        collection(db, "trainer_availability"),
        where("trainerId", "==", payload.trainerId)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        data.id = docSnapshot.id;
        trainerAvailability.push(data);
      });

      // Order availability by start time
      trainerAvailability.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      });
    } catch (error) {
      // console.error("Error fetching availability:", error);
    }

    return trainerAvailability;
  },

  // Remove availability
  async removeAvailability(context, payload) {
    try {
      await deleteDoc(doc(db, "trainer_availability", payload.id));
    } catch (error) {
      // console.error("Error removing availability:", error);
    }
  },

  // Vuex mutation to set availability dialog state
  setAddAvailabilityDialog(context, payload) {
    context.commit("setAddAvailabilityDialog", payload);
  },
};
