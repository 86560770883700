export default {
  isStripeTrainerAccountOnboarded(state) {
    return state.isStripeTrainerAccountOnboarded;
  },
  paymentIntent(state) {
    return state.paymentIntent;
  },
  stripeAccountLink(state) {
    return state.stripeAccountLink;
  },
  stripeLoginLink(state) {
    return state.stripeLoginLink;
  },
  stripeTrainerAccount(state) {
    return state.stripeTrainerAccount;
  },
  verifyStripeAccountOnboardingCompleted(state) {
    return state.verifyStripeAccountOnboardingCompleted;
  },
};
