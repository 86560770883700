<template>
  <div>
    <v-row>
      <v-col>
        <v-btn class="ma-2" icon>
          <a :href="socialMediaProfiles.youtube" target="_blank">
            <v-icon size="30px"> mdi-youtube </v-icon>
          </a>
        </v-btn>
        <v-btn class="ma-2" icon>
          <a :href="socialMediaProfiles.linkedin" target="_blank">
            <v-icon size="30px"> mdi-linkedin </v-icon>
          </a>
        </v-btn>
        <v-btn class="ma-2" icon>
          <a :href="socialMediaProfiles.facebook" target="_blank">
            <v-icon size="30px"> mdi-facebook </v-icon>
          </a>
        </v-btn>
        <v-btn class="ma-2" icon>
          <a :href="socialMediaProfiles.instagram" target="_blank">
            <v-icon size="30px"> mdi-instagram </v-icon>
          </a>
        </v-btn>
        <v-btn class="ma-2" icon>
          <a :href="socialMediaProfiles.x" target="_blank">
            <v-icon size="25px">
              <v-img :src="require('@/assets/default/x-logo.webp')"></v-img
            ></v-icon>
          </a>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      icons: "websiteData/icons",
      socialMediaProfiles: "websiteData/socialMediaProfiles",
    }),
  },
};
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}
</style>
