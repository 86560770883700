<template>
  <v-container>
    <v-row class="text-h6 font-weight-medium ma-0">Explore</v-row>
    <v-row class="ma-0 pa-0">
      <router-link class="pagelink text-body-2" to="/">Home</router-link>
    </v-row>
    <v-row class="ma-0 pa-0">
      <router-link class="pagelink text-body-2" to="/trainers"
        >Trainers
      </router-link>
    </v-row>
    <v-row class="ma-0 pa-0">
      <router-link class="pagelink text-body-2" to="/sessions"
        >Sessions
      </router-link>
    </v-row>
    <v-row class="ma-0 pa-0">
      <router-link class="pagelink text-body-2" to="/blog">Blog</router-link>
    </v-row>
    <v-row class="ma-0 pa-0">
      <router-link class="pagelink text-body-2" to="/faq">FAQ</router-link>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="!isAuthenticated">
      <router-link class="pagelink text-body-2" to="/login">Login</router-link>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="!isAuthenticated">
      <router-link class="pagelink text-body-2" to="/signup"
        >Sign up</router-link
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  methods: {},
};
</script>

<style scoped>
.pagelink {
  color: white;
  text-decoration: none;
  margin: 0px;
  margin-top: 5px;
  padding: 0px;
  /* border: 2px solid white; */
}
.pagelink:hover {
  color: rgb(235, 235, 235);
}
</style>
