import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase-init";

export default {
  async getBlogPosts(context) {
    const blogposts = [];
    try {
      const querySnapshot = await getDocs(collection(db, "blogposts"));

      querySnapshot.forEach((docSnapshot) => {
        const post = docSnapshot.data();
        post.id = docSnapshot.id;
        blogposts.push(post);
      });

      context.commit("setBlogposts", blogposts);
    } catch (error) {
      // Handle the error if necessary
      // console.error("Error fetching blog posts: ", error);
    }
  },
};
