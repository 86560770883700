import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    addEventDialog: false,
    availabilityColor: "#00cc99",
    meetingColor: "#E57373",
    sessionsTrainer: [],
    sessionsUser: [],
  }),
  mutations,
  actions,
  getters,
};
