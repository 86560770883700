export default {
  areas(state) {
    return state.areas;
  },
  certificationPhotoDefault(state) {
    return state.certificationPhotoDefault;
  },
  confirmDialog(state) {
    return state.confirmDialog;
  },
  currency(state) {
    return state.currency;
  },
  currencyDefault(state) {
    return state.currencyDefault;
  },
  drawer(state) {
    return state.drawer;
  },
  icons(state) {
    return state.icons;
  },
  socialMediaProfiles(state) {
    return state.socialMediaProfiles;
  },
  timezone(state) {
    return state.timezone;
  },
  userPhotoDefault(state) {
    return state.userPhotoDefault;
  },
};
