import { db } from "../../../firebase-init";
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";

export default {
  // Send a new notification
  async sendNewNotification(_, payload) {
    try {
      const notificationsCollection = collection(
        db,
        "notifications",
        payload.data.receiverId,
        "notifications"
      );
      await addDoc(notificationsCollection, {
        body: payload.body,
        data: payload.data,
        read: false,
        timestamp: new Date().toISOString(),
        title: payload.title,
        token: payload.token,
      });

      return true;
    } catch (error) {
      // console.log(error);
      return false;
    }
  },

  // Delete a notification
  async deleteNotification(_, payload) {
    const notificationRef = doc(
      db,
      "notifications",
      payload.userId,
      "notifications",
      payload.notificationId
    );
    await deleteDoc(notificationRef);
  },

  // Get FCM tokens for a user
  async getUserFcmTokens(_, payload) {
    let userFcmTokens = [];

    const fcmTokenQuery = query(
      collection(db, "fcm_tokens"),
      where("userId", "==", payload)
    );
    const querySnapshot = await getDocs(fcmTokenQuery);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((docSnapshot) => {
        const docData = docSnapshot.data();
        docData.tokens.forEach((token) => {
          userFcmTokens.push(token);
        });
      });
    }

    return userFcmTokens;
  },

  // Get user notifications
  async getUserNotifications(context, payload) {
    let notifications = [];

    const notificationsQuery = query(
      collection(db, "notifications", payload, "notifications"),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(notificationsQuery);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((docSnapshot) => {
        let notification = docSnapshot.data();
        notification.id = docSnapshot.id;
        notifications.push(notification);
      });
    }

    // console.log("Notifications: " + JSON.stringify(notifications));
    // console.log("Notifications length: " + notifications.length);

    context.commit("setNotifications", notifications);
    context.commit(
      "setNewMessageNotificationsCount",
      notifications.filter(
        (notification) => notification.data.type === "new-message"
      ).length
    );
    context.commit(
      "setSessionUpdateNotificationsCount",
      notifications.filter(
        (notification) => notification.data.type === "session-update"
      ).length
    );

    return notifications;
  },
};
