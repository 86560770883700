export default {
  setConfirmDialog(state, payload) {
    state.confirmDialog = payload;
  },
  setCurrency(state, payload) {
    state.currency = payload;
  },
  setDrawer(state, payload) {
    state.drawer = payload;
  },
  setTimezone(state, payload) {
    state.timezone = payload;
  },
};
