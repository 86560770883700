export default {
  setAddOrUpdateSessionDialog(state, payload) {
    state.addOrUpdateSessionDialog = payload;
  },
  setReportSessionDialog(state, payload) {
    state.reportSessionDialog = payload;
  },
  setViewSessionDialog(state, payload) {
    state.viewSessionDialog = payload;
  },
};
