import "vuetify/styles";
import { createVuetify } from "vuetify";
import { VCalendar } from "vuetify/labs/VCalendar";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const darkTheme = {
  dark: true,
  colors: {
    primary: "#EAEAEA", // Dark Gray
    secondary: "#222831", // Gray
    accent: "#82B1FF", // Blue Light
    error: "#FF5252", // Red
    info: "#2196F3", // Blue
    success: "#4CAF50", // Green
    warning: "#FFC107", // Yellow
    buttonNavbar: "#393E46", // Gray
    buttonNavbarHover: "#222831", // Dark Gray
    buttonNavbarActive: "#393E46", // Gray
    buttonNavbarActiveHover: "#222831", // Dark Gray
    button: "#222831", // Dark Gray
    buttonHover: "#222831", // Dark Gray
    buttonActive: "#393E46", // Gray
    buttonActiveHover: "#222831", // Dark Gray
    buttonSecondary: "#bdbdbd", // Dark Gray
    buttonSecondaryHover: "#bdbdbd", // Dark Gray
    buttonSecondaryActive: "#bdbdbd", // Gray
    buttonSecondaryActiveHover: "#bdbdbd", // Dark Gray
    background: "#252A34", // Dark Gray
    background1: "#252A34", // Light Gray
    background2: "#08D9D6", // Light Gray
    background3: "#222831", // White
    textPrimary: "#EEEEEE", // Light Gray
    textSecondary: "#222831", // White
    textTertiary: "#EEEEEE", // Light Gray
    textQuaternary: "#707070", // Medium Gray
    card: "#393E46", // Gray
    colorSpecial1: "#FF2E63", // Pink
    colorSpecial2: "#08D9D6", // Blue Light
    colorSpecial3: "#00ADB5", // Blue Dark
    colorSpecial4: "#252A34", // Dark Gray
    colorSpecial5: "#393E46", // Gray
    chatBackground: "#393E46", // Gray
    chatRoomBackgroundActive: "#252A34", // Gray
    chatRoomBackgroundHover: "#252A34", // Gray
    chatSenderBackground: "#EEEEEE", // Blue Light
    authBackground: "#393E46", // Gray
    dialogBackground: "#393E46", // Gray
    settingsButtonBackground: "#FFFFFF", // Gray
    settingsButtonBackgroundActive: "#EAEAEA", // White
    borderSettingsCard: "#EAEAEA", // White
    disabled: "#707070", // Medium Gray
    blogpostPreviewBackground: "#252A34", // Light Gray
  },
};

const lightTheme = {
  dark: false,
  colors: {
    primary: "#222831", // Dark Gray
    secondary: "#393E46", // Gray
    accent: "#FF2E63", // Pink
    // error: '#EAEAEA', // Light Gray
    // info: '#2196F3', // Blue
    // success: '#4CAF50', // Green
    // warning: '#FFC107', // Yellow
    buttonNavbar: "#393E46", // Gray
    buttonNavbarHover: "#222831", // Dark Gray
    buttonNavbarActive: "#393E46", // Gray
    buttonNavbarActiveHover: "#222831", // Dark Gray
    button: "#393E46", // Gray
    buttonHover: "#222831", // Dark Gray
    buttonActive: "#222831", // Dark Gray
    buttonActiveHover: "#393E46", // Gray
    buttonSecondary: "#bdbdbd", // Dark Gray
    buttonSecondaryHover: "#bdbdbd", // Dark Gray
    buttonSecondaryActive: "#bdbdbd", // Gray
    buttonSecondaryActiveHover: "#bdbdbd", // Dark Gray
    background: "#EAEAEA", // Light Gray
    background1: "#EEEEEE", // Light Gray
    background2: "#08D9D6", // Light Gray
    background3: "#FFFFFF", // White
    textPrimary: "#222831", // Dark Gray
    textSecondary: "#FFFFFF", // White
    textTertiary: "#EEEEEE", // Light Gray
    textQuaternary: "#707070", // Medium Gray
    card: "#FFFFFF", // White
    colorSpecial1: "#FF2E63", // Pink
    colorSpecial2: "#08D9D6", // Blue Light
    colorSpecial3: "#00ADB5", // Blue Dark
    colorSpecial4: "#252A34", // Dark Gray
    colorSpecial5: "#393E46", // Gray
    chatBackground: "#FFFFFF", // White
    chatRoomBackgroundActive: "#EAEAEA", // Gray
    chatRoomBackgroundHover: "#EAEAEA", // Gray
    chatSenderBackground: "#EEEEEE", // Blue Light
    authBackground: "#FFFFFF", // White
    dialogBackground: "#FFFFFF", // White
    settingsButtonBackground: "#FFFFFF", // Gray
    settingsButtonBackgroundActive: "#EAEAEA", // White
    borderSettingsCard: "#EAEAEA", // Gray
    disabled: "#707070", // Medium Gray
    blogpostPreviewBackground: "#EAEAEA", // Light Gray
  },
};

export default createVuetify({
  components: {
    VCalendar,
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      darkTheme,
      lightTheme,
    },
  },
});
