import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase-init";
import { setUpNotifications } from "../../../firebase-init";

export default {
  // Delete a user account
  async deleteAccount(_, payload) {
    // console.log("Deleting account: " + JSON.stringify(payload));
    await addDoc(collection(db, "users_to_delete"), {
      userId: payload.userId,
      email: payload.email,
      displayName: payload.displayName,
      date: new Date().toISOString(),
      deleteStatus: "requested",
    });
  },

  // Set current user by user ID
  async setCurrentUserByUserId(context, payload) {
    const customUserProfile = await context.dispatch(
      "users/getUserByUserId",
      payload.userId,
      { root: true }
    );
    context.dispatch("setCurrentUser", { ...customUserProfile });
    context.dispatch("setUserCurrencyOrDefault");
  },

  // Set current user data in the store
  setCurrentUser(context, payload) {
    context.dispatch("users/setCurrentUser", { ...payload }, { root: true });
  },

  // Set user's currency or default currency
  setUserCurrencyOrDefault(context) {
    context.dispatch(
      "websiteData/setUserCurrencyOrDefault",
      {},
      { root: true }
    );
  },

  // Set user timezone
  async setUserTimezone(context) {
    context.dispatch("websiteData/setUserTimezone", {}, { root: true });
  },

  // Create a new user
  async createUser({ dispatch }, payload) {
    let displayName = payload.google
      ? payload.firstName.trim()
      : `${payload.firstName.trim()} ${payload.lastName.trim()}`;
    let photoURL = payload.google ? payload.photoURL : "";

    // Capitalize first letters of the display name
    displayName = displayName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";

    await dispatch(
      "users/createOrUpdateUser",
      {
        id: payload.id,
        data: {
          termsAndConditionsAccepted: true,
          dateOfBirth: "",
          displayName: displayName,
          email: payload.email,
          emailVerified: "",
          from: {
            country: "",
            region: "",
          },
          gender: "",
          interestAreas: [],
          livingIn: {
            country: "",
            region: "",
          },
          photoURL: photoURL,
          timezone: timezone,
          userId: payload.userId,
          myTrainers: [],
          currency: {
            symbol: "$",
            name: "US Dollar",
            code: "USD",
            name_plural: "US dollars",
          },
        },
      },
      { root: true }
    );
  },

  // Check if the user is a trainer
  async isUserTrainer(context, payload) {
    const trainerProfile = await context.dispatch(
      "trainers/getTrainerByUserId",
      payload.userId,
      { root: true }
    );

    if (trainerProfile) {
      context.commit("setIsTrainer", true);
      context.commit(
        "setIsTrainerRegistrationApproved",
        trainerProfile.registrationRequestStatus === "approved"
      );
      context.dispatch(
        "trainers/setCurrentUserTrainerProfile",
        { ...trainerProfile },
        { root: true }
      );
    } else {
      context.commit("setIsTrainer", false);
    }
  },

  // Reset user password
  async resetpassword(_, payload) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, payload.email, {
        url: payload.continueUrl,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  },

  // Verify user email
  async verifyemail(_, payload) {
    const auth = getAuth();
    let user = auth.currentUser;
    let counter = 0;

    while (!user && counter < 10) {
      user = auth.currentUser;
      counter++;
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (user) {
      try {
        await sendEmailVerification(user, {
          url: payload.continueUrl,
        });
      } catch (error) {
        throw new Error(error.message);
      }
    } else {
      throw new Error("No user is signed in!");
    }
  },

  // Sign in with Google
  async signingoogle(context) {
    return await context.dispatch("auth", { mode: "google" });
  },

  // User login
  async login(context, payload) {
    return await context.dispatch("auth", { ...payload, mode: "login" });
  },

  // User signup
  async signup(context, payload) {
    return await context.dispatch("auth", { ...payload, mode: "signup" });
  },

  // Handle user authentication
  async auth(context, payload) {
    let userData;
    let id;

    switch (payload.mode) {
      case "login":
        userData = await signInWithEmailAndPassword(
          getAuth(),
          payload.email,
          payload.password
        );
        break;

      case "signup":
        userData = await createUserWithEmailAndPassword(
          getAuth(),
          payload.email,
          payload.password
        );
        await sendEmailVerification(getAuth().currentUser, {
          url: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL,
        });
        id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
          .toString()
          .slice(0, 9);
        await context.dispatch("createUser", {
          id,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          userId: userData.user.uid,
        });
        await context.dispatch(
          "email/sendNewUserWelcomeEmail",
          {
            userEmail: payload.email,
            userDisplayName: `${payload.firstName} ${payload.lastName}`,
          },
          { root: true }
        );
        break;

      case "google": {
        userData = await signInWithPopup(getAuth(), new GoogleAuthProvider());

        const user = await context.dispatch(
          "users/getUserByUserId",
          userData.user.uid,
          { root: true }
        );

        if (!user) {
          id = Math.floor(
            Math.random() * Math.floor(Math.random() * Date.now())
          )
            .toString()
            .slice(0, 9);
          await context.dispatch("createUser", {
            id,
            firstName: userData.user.displayName,
            email: userData.user.email,
            userId: userData.user.uid,
            photoURL: userData.user.photoURL,
            google: true,
          });
          await context.dispatch(
            "email/sendNewUserWelcomeEmail",
            {
              userEmail: userData.user.email,
              userDisplayName: userData.user.displayName,
            },
            { root: true }
          );
        }
        break;
      }

      default:
        throw new Error("Invalid request!");
    }

    context.commit("setUserId", userData.user.uid);
    context.commit("setEmailVerified", userData.user.emailVerified);
    context.commit("setIsAuthenticated", true);

    context.dispatch("isUserTrainer", {
      userId: userData.user.uid,
    });

    if (userData.user.emailVerified) {
      await context.dispatch("setCurrentUserByUserId", {
        userId: userData.user.uid,
      });
    }

    setTimeout(() => {
      setUpNotifications();
    }, 3000);
  },

  // Auto login the user
  async autoLogin(context) {
    context.dispatch("setUserCurrencyOrDefault");
    context.dispatch("setUserTimezone");

    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await context.dispatch("setCurrentUserByUserId", { userId: user.uid });
        context.dispatch("isUserTrainer", { userId: user.uid });
        context.commit("setUserId", user.uid);
        context.commit("setEmailVerified", user.emailVerified);
        context.commit("setIsAuthenticated", true);
      }
    });

    setTimeout(() => {
      context.commit("setAutoLoginCompleted", true);
    }, 1500);
  },

  // Logout the user
  logout(context) {
    getAuth()
      .signOut()
      .then(() => {
        context.commit("setUserId", null);
        context.commit("setEmailVerified", false);
        context.commit("setIsAuthenticated", false);
        context.commit("setIsTrainer", false);
        context.commit("setIsTrainerRegistrationApproved", false);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  },
};
