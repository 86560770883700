import axios from "axios";
import { auth } from "@/firebase-init.js";

// Get the Firebase user ID token
async function getFirebaseUserIdToken() {
  let userIdToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : null;
  return userIdToken;
}

export default {
  // Create a Stripe payment intent
  async createPaymentIntent(context, payload) {
    // console.log('Calling createPaymentIntent with payload: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const paymentIntent = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/payment_intents",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    context.commit("setPaymentIntent", paymentIntent.data);

    return paymentIntent.data;
  },

  // Create a connected Stripe account
  async createConnectedAccount(_, payload) {
    // console.log('Creating new stripe account for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const connectedAccount = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/accounts",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    return connectedAccount.data;
  },

  // Create a Stripe account link
  async createStripeAccountLink(context, payload) {
    // console.log('Creating new stripe account link for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const accountLink = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/account_links",
      {
        ...payload,
        platformURL: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL,
      },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    context.commit("setStripeAccountLink", accountLink.data.url);
  },

  // Create a Stripe login link
  async createStripeLoginLink(context, payload) {
    // console.log('Creating new stripe login link for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const loginLink = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/login_links",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    context.commit("setStripeLoginLink", loginLink.data.url);
  },

  // Create a Stripe transfer
  async createTransfer(_, payload) {
    // console.log('Creating new stripe transfer for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const transfer = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/transfers",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    return transfer.data;
  },

  // Create a Stripe refund
  async createRefund(_, payload) {
    // console.log('Creating new stripe refund for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const refund = await axios.post(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL + "/refunds",
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    return refund.data;
  },

  // Retrieve the connected Stripe account
  async retrieveConnectedAccount(context, payload) {
    // console.log('Getting stripe account for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const connectedAccount = await axios.get(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL +
        "/accounts/" +
        payload.stripeTrainerAccountId,
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    context.commit("setStripeTrainerAccount", connectedAccount.data);

    return connectedAccount.data;
  },

  // Retrieve the Stripe payment intent
  async retrievePaymentIntent(_, payload) {
    // console.log('Getting stripe payment intent for: ' + JSON.stringify(payload));

    const userIdToken = await getFirebaseUserIdToken();
    const paymentIntent = await axios.get(
      process.env.VUE_APP_STRIPE_ACTIONS_FUNCTION_URL +
        "/payment_intents/" +
        payload.paymentIntentId,
      {
        headers: {
          Authorization: `Bearer ${userIdToken}`,
        },
      }
    );

    return paymentIntent.data;
  },

  // Verify if the Stripe Trainer account is onboarded
  async verifyStripeTrainerAccountOnboarding(context, userId) {
    try {
      const trainerProfile = await context.dispatch(
        "trainers/getTrainerByUserId",
        userId,
        {
          root: true,
        }
      );
      if (trainerProfile) {
        await context.dispatch("retrieveConnectedAccount", {
          stripeTrainerAccountId: trainerProfile.stripeAccountId,
        });
        const stripeTrainerAccount = context.getters.stripeTrainerAccount;
        const isOnboarded =
          stripeTrainerAccount !== null &&
          stripeTrainerAccount.charges_enabled === true;
        context.commit("setIsStripeTrainerAccountOnboarded", isOnboarded);
      }
      context.commit("setVerifyStripeAccountOnboardingCompleted", true);
    } catch (error) {
      context.commit("setIsStripeTrainerAccountOnboarded", true);
      context.commit("setVerifyStripeAccountOnboardingCompleted", true);
    }
  },
};
