export default {
  sessionsTrainer(state) {
    return state.sessionsTrainer;
  },
  sessionsUser(state) {
    return state.sessionsUser;
  },
  addEventDialog(state) {
    return state.addEventDialog;
  },
  availabilityColor(state) {
    return state.availabilityColor;
  },
  meetingColor(state) {
    return state.meetingColor;
  },
};
