import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    isStripeTrainerAccountOnboarded: true,
    paymentIntent: null,
    stripeAccountLink: null,
    stripeLoginLink: null,
    stripeTrainerAccount: null,
    verifyStripeAccountOnboardingCompleted: false,
  }),
  mutations,
  actions,
  getters,
};
