<template>
  <v-icon end dark>
    <slot></slot>
  </v-icon>
</template>

<script>
export default {};
</script>

<style scoped></style>
