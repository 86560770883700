export default {
  setIsStripeTrainerAccountOnboarded(state, payload) {
    state.isStripeTrainerAccountOnboarded = payload;
  },
  setPaymentIntent(state, payload) {
    state.paymentIntent = payload;
  },
  setStripeAccountLink(state, payload) {
    state.stripeAccountLink = payload;
  },
  setStripeLoginLink(state, payload) {
    state.stripeLoginLink = payload;
  },
  setStripeTrainerAccount(state, payload) {
    state.stripeTrainerAccount = payload;
  },
  setVerifyStripeAccountOnboardingCompleted(state, payload) {
    state.verifyStripeAccountOnboardingCompleted = payload;
  },
};
