export default {
  setActiveRoomId(state, id) {
    state.activeRoomId = id;
  },
  setActiveRoomName(state, name) {
    state.activeRoomName = name;
  },
  setActiveUsers(state, users) {
    state.activeUsers = users;
  },
  setRoomMessages(state, messages) {
    state.roomMessages = [...messages];
  },
  clearRoom(state) {
    state.activeRoomId = null;
    state.activeRoomName = null;
    state.users = [];
  },
  setUsers(state, users) {
    state.users = users;
  },
};
