<template>
  <v-container>
    <v-row class="ma-0"
      ><p class="text-h5 font-weight-medium">NetworkTal</p></v-row
    >
    <v-row class="ma-0 pt-1">
      <p class="text-body-2 text-start">
        Your path to success begins here. Connect with skilled trainers in your
        desired area of expertise and book sessions for personalized guidance
        and feedback.
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
