<template>
  <div class="ma-0 pa-0">
    <v-banner stacked v-if="!cookiesConsent" class="cookies-banner">
      <template v-slot:text>
        Website uses cookies to enable the use of the website. Please see our
        <router-link to="/cookie-policy" target="_blank"
          >Cookie Policy</router-link
        >
        for more information. By clicking "Accept Cookies" or continuing to use
        the site, you agree to the use of cookies.
      </template>
      <v-spacer></v-spacer>
      <base-button
        class="text-none mr-3 text-gray"
        color="white"
        @click="acceptCookies"
        style="color: rgb(var(--v-theme-primary)) !important"
      >
        Accept Cookies
      </base-button>
    </v-banner>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookiesConsent: false,
    };
  },
  created() {
    this.getCookiesConsent();
  },
  methods: {
    getCookiesConsent() {
      const savedCookiesConsent = localStorage.getItem("cookies-consent");

      if (savedCookiesConsent) {
        if (savedCookiesConsent === "true") {
          this.cookiesConsent = true;
        } else {
          this.cookiesConsent = false;
        }
      } else {
        this.cookiesConsent = false;
      }
    },
    acceptCookies() {
      localStorage.setItem("cookies-consent", "true");
      this.cookiesConsent = true;
    },
  },
};
</script>

<style scoped>
.cookies-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2000;
  background-color: rgb(var(--v-theme-primary)) !important;
  color: white;
  opacity: 0.95;
}

a {
  color: white;
  text-decoration: underline;
}
</style>
