import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase-init";
import store from "../store/index.js";

export const roomService = {
  get,
  createPrivateChat,
  createChatRoom,
  getRoomDetail,
  sendMessage,
};

// Get existing private chat room
async function get(currentUser, targetUser) {
  try {
    const roomsQuery = query(
      collection(db, "rooms"),
      where("isPrivate", "==", true),
      where("users", "array-contains", currentUser)
    );
    const snapshot = await getDocs(roomsQuery);

    if (snapshot.empty) {
      return { success: false, error: "No chat room(s)" };
    }

    let roomId;
    let success = false;

    snapshot.forEach((docSnapshot) => {
      if (docSnapshot.data().users.includes(targetUser)) {
        roomId = docSnapshot.id;
        success = true;
      }
    });

    return { success, roomID: roomId };
  } catch (error) {
    return handleError(error);
  }
}

// Create a new private chat room
async function createPrivateChat(currentUser, targetUser) {
  try {
    const data = {
      isPrivate: true,
      users: [currentUser, targetUser],
    };
    const docRef = await addDoc(collection(db, "rooms"), data);
    return { success: true, roomID: docRef.id };
  } catch (error) {
    return handleError(error);
  }
}

// Get room details
async function getRoomDetail(room) {
  try {
    const roomDoc = await getDoc(doc(db, "rooms", room));

    if (!roomDoc.exists()) {
      return { success: false, error: "No chat room(s)" };
    }

    return { success: true, userIds: roomDoc.data().users };
  } catch (error) {
    return handleError(error);
  }
}

// Send a message to the chat room
async function sendMessage(sender, room, message) {
  try {
    const now = new Date();
    const data = {
      sender,
      message,
      timestamp: now.toISOString(),
    };

    // Get recipient user ID
    const roomActiveUsers = store.getters["room/activeUsers"];
    const receiverUserId = roomActiveUsers.find((user) => user !== sender);

    // Get sender profile details
    const currentUser = store.getters["users/currentUser"];

    // Get receiver user FCM tokens
    const receiverFcmTokens = await store.dispatch(
      "notifications/getUserFcmTokens",
      receiverUserId
    );

    // Add the message to the Firestore
    const messageDoc = await addDoc(
      collection(db, "rooms", room, "messages"),
      data
    );

    // Send notifications
    receiverFcmTokens.forEach((token) => {
      store.dispatch("notifications/sendNewNotification", {
        title: `New message from ${currentUser.displayName}`,
        body: message,
        token,
        data: {
          roomId: room,
          senderId: sender,
          senderName: currentUser.displayName,
          receiverId: receiverUserId,
          type: "new-message",
        },
      });
    });

    return { success: true, messageID: messageDoc.id };
  } catch (error) {
    return handleError(error);
  }
}

// Create a new chat room
async function createChatRoom(userIDs) {
  try {
    const data = {
      isPrivate: userIDs.length <= 2,
      users: userIDs,
    };
    const docRef = await addDoc(collection(db, "rooms"), data);
    return { success: true, roomID: docRef.id };
  } catch (error) {
    return handleError(error);
  }
}

// Handle any errors
function handleError(error) {
  // console.log('Ops! ' + error);
  return Promise.reject(error);
}
