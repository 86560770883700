<template>
  <div>
    <base-card
      class="ma-0 pa-0 mx-2 mb-2 py-1 pr-1 notification-card"
      :style="{
        'border-left':
          '4px solid rgb(var(--v-theme-' + notification.color + '))',
      }"
    >
      <base-loader v-if="!notification"></base-loader>
      <div v-else>
        <div v-if="notification.link">
          <router-link :to="notification.link"
            ><v-row
              class="ma-0 pa-0 text-caption"
              justify="center"
              align="center"
            >
              <v-col class="ma-0 pa-0" cols="1" align="center">
                <v-icon size="small" class="pr-2" :color="notification.color">{{
                  notification.icon
                }}</v-icon>
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0 font-weight-bold">{{
                    notification.title
                  }}</v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0" style="font-size: 10px">{{
                    notification.message
                  }}</v-col>
                </v-row>
              </v-col>
            </v-row></router-link
          >
        </div>
        <div v-else>
          <v-row class="ma-0 pa-0 text-caption" justify="center" align="center">
            <v-col class="ma-0 pa-0" cols="1" align="center">
              <v-icon size="small" class="pr-2" :color="notification.color">{{
                notification.icon
              }}</v-icon>
            </v-col>
            <v-col class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0 font-weight-bold">{{
                  notification.title
                }}</v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" style="font-size: 10px">{{
                  notification.message
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    notification: {
      required: true,
    },
  },
};
</script>

<style scoped>
.notification-card {
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 3px !important;
}

.notification-card a {
  cursor: pointer;
  text-decoration: none !important;
  color: rgb(var(--v-theme-primary));
}
</style>
