<template>
  <v-app>
    <websiteloader v-if="!autoLoginCompleted"></websiteloader>
    <div class="ma-0 pa-0" v-else>
      <cookiebanner></cookiebanner>
      <appbar></appbar>
      <becometrainernowannouncement></becometrainernowannouncement>
      <v-main id="app" style="--v-layout-top: 0px">
        <router-view />
      </v-main>
    </div>
    <appfooter v-if="autoLoginCompleted"></appfooter>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useTheme } from "vuetify";
import appbar from "@/components/navbar/appbar.vue";
import appfooter from "@/components/footer/appfooter.vue";
import cookiebanner from "@/components/cookiesettings/cookiebanner.vue";
import becometrainernowannouncement from "@/components/announcements/becometrainernow.vue";
import websiteloader from "@/components/app/websiteloader.vue";

export default {
  inject: ["eventBus"],
  name: "App",
  data() {
    return {
      themeVuetify: null,
    };
  },
  components: {
    appbar,
    appfooter,
    cookiebanner,
    becometrainernowannouncement,
    websiteloader,
  },
  created() {
    this.themeVuetify = useTheme();
    this.autoLogin();
    this.applyTheme();
    this.eventBus.on("apply-theme", this.applyTheme);
  },
  computed: {
    ...mapGetters({
      autoLoginCompleted: "auth/autoLoginCompleted",
    }),
  },
  mounted() {
    this.addSchemaMarkup();
  },
  methods: {
    ...mapActions({
      autoLogin: "auth/autoLogin",
    }),
    applyTheme() {
      const savedTheme = localStorage.getItem("dark-theme");

      if (savedTheme) {
        this.darkTheme = savedTheme;
      }

      if (this.darkTheme === "true") {
        this.themeVuetify.global.name = "darkTheme";
      } else {
        this.themeVuetify.global.name = "lightTheme";
      }
    },
    addSchemaMarkup() {
      const schemaData = [
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://www.networktal.com",
          name: "NetworkTal",
          description:
            "NetworkTal: Find trainers for one-on-one learning, skill development, and career advancement. Connect with experts for online education and personal growth.",
        },
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "NetworkTal, LLC",
          url: "https://www.networktal.com",
          logo: "https://www.networktal.com/networktal.png",
          contactPoint: {
            "@type": "ContactPoint",
            email: "contact@networktal.com",
            contactType: "Customer Support",
          },
          sameAs: [
            "https://www.facebook.com/profile.php?id=100090834973914",
            "https://x.com/networktalcom",
            "https://www.linkedin.com/company/networktal",
            "https://www.instagram.com/networktal/",
            "https://www.youtube.com/channel/UC6qYkcwsFnZ3I4vXMBrCzPg",
          ],
        },
      ];

      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.text = JSON.stringify(schemaData);
      document.head.appendChild(script);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: x-large;
}

#app {
  min-height: 600px;
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-textPrimary));
}
</style>
