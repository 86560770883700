export default {
  addOrUpdateCertificationDialog(state) {
    return state.addOrUpdateCertificationDialog;
  },
  areas(state) {
    return state.areas;
  },
  currentUserTrainerProfile(state) {
    return state.currentUserTrainerProfile;
  },
  filter(state) {
    return state.filter;
  },
  hasTrainers(state) {
    return state.trainers && state.trainers.length > 0;
  },
  isFilterApplied(state) {
    for (let key in state.filter) {
      if (state.filter[key]) {
        return true;
      }
    }
    return false;
  },
  isTrainer(_, getters, _2, rootGetters) {
    const trainers = getters.trainers;
    const userId = rootGetters.userId;
    return trainers.some((trainer) => trainer.id === userId);
  },
  messageTrainerDialog(state) {
    return state.messageTrainerDialog;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimestamp = new Date().getTime();
    return (currentTimestamp - lastFetch) / 1000 > 60;
  },
  trainers(state) {
    return state.trainers;
  },
  viewCertificationDialog(state) {
    return state.viewCertificationDialog;
  },
};
