import { roomService } from "../../../_services/room.service.js";
import { db } from "../../../firebase-init";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

export default {
  // Get rooms by userId where the user is part of the room
  async getRooms(_, { userId }) {
    const rooms = [];
    const q = query(
      collection(db, "rooms"),
      where("users", "array-contains", userId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((docSnapshot) => {
      const room = docSnapshot.data();
      room.id = docSnapshot.id;
      rooms.push(room);
    });

    return rooms;
  },

  // Fetch room messages by roomId
  async getRoomMessages({ commit }, { roomId }) {
    const newChats = [];

    if (roomId) {
      const roomRef = doc(db, "rooms", roomId);
      const messagesRef = collection(roomRef, "messages");
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            // Check if the previous sender is the same to determine continuation
            let continuation = false;
            if (newChats.length > 0) {
              if (
                newChats[newChats.length - 1].senderID ==
                change.doc.data().sender
              ) {
                continuation = true;
              }
            }

            const chat = {
              id: change.doc.id,
              message: change.doc.data().message,
              timestamp: change.doc.data().timestamp,
              senderID: change.doc.data().sender,
              continuation: continuation,
            };
            newChats.push(chat);
          }
        });

        commit("setRoomMessages", newChats);
      });
    } else {
      // console.warn(
      //   "Warning: Room messages could not be retrieved as no room id was provided!"
      // );
    }
  },

  // Select and set the active room
  async selectRoom({ commit }, { id, name, currentUser, targetUser }) {
    let roomID;

    if (id) {
      roomID = id;

      if (name) {
        commit("setActiveRoomName", name);
      }

      // Fetch users in the selected room and set them as active users
      const roomDoc = await getDoc(doc(db, "rooms", roomID));
      if (roomDoc.exists()) {
        commit("setActiveUsers", roomDoc.data().users);
      }
    } else {
      // Check if there is an existing chat room for the current user and target user
      const roomResp = await roomService.get(currentUser, targetUser);

      if (roomResp.success) {
        roomID = roomResp.roomID;
      } else {
        const resp = await roomService.createPrivateChat(
          currentUser,
          targetUser
        );
        if (resp.success) {
          roomID = resp.roomID;
        }
      }

      commit("setActiveUsers", [currentUser, targetUser]);
    }

    commit("setActiveRoomId", roomID);
  },

  // Send message in the active chat room
  async sendMessage({ state }, { message, senderId }) {
    const room = state.activeRoomId;
    const resp = await roomService.sendMessage(senderId, room, message);
    return resp;
  },

  // Create a new chat room
  async createChatRoom({ userIDs }) {
    const resp = await roomService.createChatRoom(userIDs);
    return resp;
  },

  // Clear room data from the Vuex store
  clearRoom({ commit }) {
    commit("clearRoom");
  },
};
