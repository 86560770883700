import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import store from "./store/index.js";

// Initialize Firebase app with environment variables
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const messaging = getMessaging(app);
const perf = getPerformance(app);

if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.VUE_APP_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

// Add or update the FCM token in the 'fcm_tokens' collection for the user
async function addOrUpdateToken(token, currentUser) {
  try {
    // Check if currentUser and currentUser.userId are valid
    if (!currentUser || !currentUser.userId) {
      // console.error("Invalid user: currentUser or userId is undefined.");
      return;
    }

    // Query the Firestore collection for the current user's FCM tokens
    const tokenQuery = query(
      collection(db, "fcm_tokens"),
      where("userId", "==", currentUser.userId)
    );
    const querySnapshot = await getDocs(tokenQuery);

    // If user exists in the fcm_tokens collection
    if (!querySnapshot.empty) {
      let tokenExists = false;

      // Check if the token already exists in the tokens array
      querySnapshot.forEach((docSnapshot) => {
        if (docSnapshot.data().tokens.includes(token)) {
          tokenExists = true;
        }
      });

      // If token does not exist, update the document
      if (!tokenExists) {
        const existingTokens = querySnapshot.docs[0].data().tokens || [];
        existingTokens.push(token);

        await updateDoc(doc(db, "fcm_tokens", querySnapshot.docs[0].id), {
          tokens: existingTokens,
          userId: currentUser.userId,
        });
      }
    } else {
      // If user does not exist, create a new document with the token
      await addDoc(collection(db, "fcm_tokens"), {
        tokens: [token],
        userId: currentUser.userId,
      });
    }
  } catch (error) {
    // console.error("Error updating token: ", error);
  }
}

// Set up notification permissions and retrieve FCM token
export async function setUpNotifications() {
  const currentUser = store.getters["users/currentUser"];

  if (currentUser) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_VAPID_KEY,
        });
        if (currentToken) {
          addOrUpdateToken(currentToken, currentUser);
        } else {
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
        }
      } catch (err) {
        // console.error("An error occurred while retrieving token. ", err);
      }
    } else {
      // console.warn("Unable to get permission to notify.");
    }
  }
}

// Initialize notification setup after a delay
setTimeout(() => {
  setUpNotifications();
}, 3000);

export { db, auth, messaging, perf, onMessage, appCheck };
