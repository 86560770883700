import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  uploadProfilePicture(context, payload) {
    // console.log("Payload image: " + JSON.stringify(payload));
    const storage = getStorage();
    const uploadPath =
      "usersdata/" + payload.userId + "/profilepictures/" + payload.image.name;
    const storageRef = ref(storage, uploadPath);

    uploadBytes(storageRef, payload.image).then((snapshot) => {
      // console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        context.commit("setDownloadURL", downloadURL);
      });
    });
  },
  uploadTrainerCertificationPicture(context, payload) {
    // console.log("Payload image: " + payload)
    const storage = getStorage();
    const uploadPath =
      "trainersdata/" +
      payload.trainerId +
      "/certificationpictures/" +
      payload.image.name;
    const storageRef = ref(storage, uploadPath);

    uploadBytes(storageRef, payload.image).then((snapshot) => {
      // console.log('Uploaded a blob or file!')
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        context.commit("setCertificationDownloadURL", downloadURL);
      });
    });
  },
  clearState(context) {
    context.commit("setDownloadURL", "");
  },
  clearCertificationDownloadURL(context) {
    context.commit("setCertificationDownloadURL", "");
  },
};
