<template>
  <v-dialog v-model="localModel" :max-width="customWidth">
    <v-card id="base-dialog-card">
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inject: ["eventBus"],
  props: {
    model: {
      type: Boolean,
      required: true,
    },
    customWidth: {
      type: String,
      required: false,
      default: "600px",
    },
  },
  computed: {
    localModel: {
      get() {
        return this.model;
      },
      set(newValue) {
        this.eventBus.emit("set-base-dialog", newValue);
      },
    },
  },
};
</script>

<style scoped>
#base-dialog-card {
  background-color: rgb(var(--v-theme-dialogBackground)) !important;
  border-radius: 10px;
}
</style>
