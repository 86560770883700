<template>
  <v-navigation-drawer
    v-model="menuDrawer"
    location="bottom"
    disable-resize-watcher
    style="height: auto"
    touchless
  >
    <v-list>
      <v-list-item to="/" class="">
        <template v-slot:prepend>
          <v-icon size="small">mdi-home</v-icon>
        </template>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item to="/trainers">
        <template v-slot:prepend>
          <v-icon size="small">mdi-account-search</v-icon>
        </template>
        <v-list-item-title>Trainers</v-list-item-title>
      </v-list-item>
      <v-list-item to="/sessions">
        <template v-slot:prepend>
          <v-icon size="small">mdi-clipboard-text-search-outline</v-icon>
        </template>
        <v-list-item-title>Sessions</v-list-item-title>
      </v-list-item>
      <v-list-item to="/blog">
        <template v-slot:prepend>
          <v-icon size="small">mdi-post-outline</v-icon>
        </template>
        <v-list-item-title>Blog</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isAuthenticated" to="/messages">
        <template v-slot:prepend>
          <v-badge
            v-if="newMessageNotificationsCount > 0"
            :content="newMessageNotificationsCount"
            :value="newMessageNotificationsCount"
            color="red"
            location="top start"
            floating
          >
          </v-badge>
          <v-icon size="small">mdi-message-text-outline</v-icon>
        </template>
        <v-list-item-title>Chat</v-list-item-title>
      </v-list-item>
      <v-list-group v-if="isTrainer && isAuthenticated">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <template v-slot:prepend>
              <v-badge
                v-if="sessionUpdateNotificationsCount > 0"
                :content="sessionUpdateNotificationsCount"
                :value="sessionUpdateNotificationsCount"
                color="red"
                location="top start"
                floating
              >
              </v-badge>
              <v-icon size="small">mdi-account-tie</v-icon>
            </template>
            <v-list-item-title>Trainer</v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/settings/trainer/dashboard">
          <template v-slot:prepend>
            <v-badge
              v-if="
                !isStripeTrainerAccountOnboarded &&
                verifyStripeAccountOnboardingCompleted
              "
              color="warning"
              icon="mdi-exclamation"
              location="top start"
              floating
            >
            </v-badge>
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
          </template>
          <v-list-item-title>Trainer Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/settings/trainer/mysessions"
          @click="deleteSessionUpdateNotifications"
        >
          <template v-slot:prepend>
            <v-badge
              v-if="sessionUpdateNotificationsCount > 0"
              :content="sessionUpdateNotificationsCount"
              :value="sessionUpdateNotificationsCount"
              color="red"
              location="top start"
              floating
            >
            </v-badge>
            <v-icon size="small">mdi-clipboard-text</v-icon>
          </template>
          <v-list-item-title class="pl-2">My Sessions</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/trainer/profile/information">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-account-cog</v-icon>
          </template>
          <v-list-item-title>Trainer Profile Settings</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/trainer/sessions">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-clipboard-text</v-icon>
          </template>
          <v-list-item-title>Session Types Management</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/trainer/availability">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-calendar-clock</v-icon>
          </template>
          <v-list-item-title>Availability Management</v-list-item-title>
        </v-list-item>
        <v-list-item :to="trainerProfileURL()">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-account-tie</v-icon>
          </template>
          <v-list-item-title>My Trainer Profile</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/trainer/myexplorers">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-account-school</v-icon>
          </template>
          <v-list-item-title>My Explorers</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/trainer/mypayments">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-credit-card</v-icon>
          </template>
          <v-list-item-title>My Payments</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="isAuthenticated">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <template v-slot:prepend>
              <v-icon size="small" start>mdi-account</v-icon>
            </template>
            <v-list-item-title>Explorer</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item to="/settings/user/dashboard">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
          </template>
          <v-list-item-title>Explorer Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/user/profile/basicinformation">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-account-cog</v-icon>
          </template>
          <v-list-item-title>Explorer Profile Settings</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/user/mytrainers">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-account-tie</v-icon>
          </template>
          <v-list-item-title>My Trainers</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/user/mysessions">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-calendar</v-icon>
          </template>
          <v-list-item-title>My Explorer Sessions</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isTrainer" to="/becometrainer/register">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-plus</v-icon>
          </template>
          <v-list-item-title>Become a Trainer</v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings/user/general">
          <template v-slot:prepend>
            <v-icon size="small" start>mdi-cog</v-icon>
          </template>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item v-if="!isAuthenticated" to="/login" class="">
        <template v-slot:prepend>
          <v-icon size="small">mdi-login</v-icon>
        </template>
        <v-list-item-title>Login</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!isAuthenticated" to="/signup" class="">
        <template v-slot:prepend>
          <v-icon size="small">mdi-account-plus</v-icon>
        </template>
        <v-list-item-title>Signup</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isAuthenticated" to="/" class="" @click="logout">
        <template v-slot:prepend>
          <v-icon size="small">mdi-logout</v-icon>
        </template>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  inject: ["eventBus"],
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      menuDrawer: false,
      trainerProfile: null,
    };
  },
  created() {
    this.eventBus.on("toggle-nav-drawer", () => {
      this.menuDrawer = !this.menuDrawer;
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isAuthenticated: "auth/isAuthenticated",
      isStripeTrainerAccountOnboarded:
        "payments/isStripeTrainerAccountOnboarded",
      isTrainer: "auth/isTrainer",
      isTrainerRegistrationApproved: "auth/isTrainerRegistrationApproved",
      newMessageNotificationsCount:
        "notifications/newMessageNotificationsCount",
      notifications: "notifications/notifications",
      sessionUpdateNotificationsCount:
        "notifications/sessionUpdateNotificationsCount",
      userId: "auth/userId",
    }),
  },
  methods: {
    ...mapActions({
      deleteNotification: "notifications/deleteNotification",
      getUserNotifications: "notifications/getUserNotifications",
      logout: "auth/logout",
      setDrawer: "websiteData/setDrawer",
      verifyStripeTrainerAccountOnboarding:
        "payments/verifyStripeTrainerAccountOnboarding",
      verifyStripeAccountOnboardingCompleted:
        "payments/verifyStripeAccountOnboardingCompleted",
    }),
    async deleteSessionUpdateNotifications() {
      // console.log('Deleting session update notifications')
      let userNotifications = this.getUserSessionUpdateNotifications();
      if (userNotifications.length > 0) {
        userNotifications.forEach((notification) => {
          this.deleteNotification({
            userId: this.userId,
            notificationId: notification.id,
          });
        });
      }
      this.getUserNotifications(this.userId);
    },
    getUserSessionUpdateNotifications() {
      // filter notifications that have as sender the roomUserId
      return this.notifications.filter(
        (notification) =>
          notification.data.receiverId == this.userId &&
          notification.data.type == "session-update"
      );
    },
    trainerProfileURL() {
      if (this.currentUserTrainerProfile !== null) {
        return (
          "/trainer/" +
          this.createTrainerProfileDisplayNamePath(
            this.currentUserTrainerProfile.displayName
          ) +
          "-" +
          this.currentUserTrainerProfile.id
        );
      } else {
        return "/trainers";
      }
    },
  },
};
</script>

<style scoped></style>
