export default {
  autoLoginCompleted(state) {
    return state.autoLoginCompleted;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isEmailVerified(state) {
    return state.isEmailVerified;
  },
  isTrainer(state) {
    return state.isTrainer;
  },
  isTrainerRegistrationApproved(state) {
    return state.isTrainerRegistrationApproved;
  },
  userId(state) {
    return state.userId;
  },
};
