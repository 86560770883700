<template>
  <div>
    <v-app-bar flat color="colorSpecial5" style="z-index: 3">
      <appbaricon></appbaricon>
      <appbartitle></appbartitle>
      <v-spacer />
      <appbarbuttons></appbarbuttons>
      <navmenubutton></navmenubutton>
    </v-app-bar>
    <navdrawer></navdrawer>
  </div>
</template>

<script>
import appbaricon from "./appbaricon.vue";
import appbartitle from "./appbartitle.vue";
import appbarbuttons from "./appbarbuttons.vue";
import navdrawer from "./navdrawer.vue";
import navmenubutton from "./navmenubutton.vue";

export default {
  components: {
    appbaricon,
    appbartitle,
    appbarbuttons,
    navdrawer,
    navmenubutton,
  },
};
</script>

<style scoped></style>
