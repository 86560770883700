<template>
  <div>
    <base-dialog :model="confirmDialog" customWidth="500px">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row class="ma-0 pa-0" align="center">
          <v-col align="start" cols="10">{{ toolbarText }}</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="submitNotConfirm" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container class="confirm-delete-dialog">
        <v-row class="my-3 mx-3">
          <v-col class="confirm-delete-dialog-title" align="center">
            <p>
              Are you sure you want to {{ displayText }}? {{ displayTextExtra }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col align="center">
            <base-button class="mx-5" @click="submitConfirm()">Yes</base-button>
            <base-button
              class="mx-5"
              customColor="grey"
              @click="submitNotConfirm()"
            >
              No
            </base-button>
          </v-col>
        </v-row>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    displayText: {
      type: String,
      required: true,
    },
    displayTextExtra: {
      type: String,
      required: false,
      default: null,
    },
    eventName: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
      default: null,
    },
    toolbarText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setConfirmDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      setConfirmDialog: "websiteData/setConfirmDialog",
    }),
    submitConfirm() {
      this.setConfirmDialog(false);
      if (this.payload !== null) {
        this.eventBus.emit(this.eventName, this.payload);
      } else {
        this.eventBus.emit(this.eventName);
      }
    },
    submitNotConfirm() {
      this.setConfirmDialog(false);
    },
  },
};
</script>

<style scoped>
.confirm-delete-dialog {
  font-size: 16px;
  padding-top: 50px;
}

.confirm-delete-dialog-title {
  font-weight: 400;
}
</style>
