export default {
  setSessionsTrainer(state, payload) {
    state.sessionsTrainer = payload;
  },
  setSessionsUser(state, payload) {
    state.sessionsUser = payload;
  },
  setAddEventDialog(state, payload) {
    state.addEventDialog = payload;
  },
};
