<template>
  <span class="hidden-md-and-up">
    <v-btn class="mx-2" @click="toggleNavDrawer" rounded="0">
      <v-icon size="large">mdi-menu</v-icon>
    </v-btn>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  methods: {
    ...mapActions({
      setDrawer: "websiteData/setDrawer",
    }),
    toggleNavDrawer() {
      this.eventBus.emit("toggle-nav-drawer");
    },
  },
};
</script>

<style scoped></style>
