export default {
  setAddOrUpdateCertificationDialog(state, payload) {
    state.addOrUpdateCertificationDialog = payload;
  },
  registerTrainer(state, payload) {
    state.trainers.push(payload);
  },
  setCurrentUserTrainerProfile(state, payload) {
    state.currentUserTrainerProfile = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  setFilter(state, payload) {
    state.filter = payload;
  },
  setMessageTrainerDialog(state, payload) {
    state.messageTrainerDialog = payload;
  },
  setTrainers(state, payload) {
    state.trainers = payload;
  },
  setViewCertificationDialog(state, payload) {
    state.viewCertificationDialog = payload;
  },
};
