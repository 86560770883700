import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters";

export default {
  namespaced: true,
  state: () => ({
    addOrUpdateCertificationDialog: false,
    areas: [
      "Adventure and Outdoors",
      "AI and Machine Learning",
      "Arts and Crafts",
      "Blockchain and Cryptocurrency",
      "Business and Finance",
      "Coaching and Mentoring",
      "Cooking and Baking",
      "Cloud Computing and DevOps",
      "Cybersecurity and Information Security",
      "Data Science and Analytics",
      "Digital Marketing",
      "Education and Teaching",
      "Fashion and Beauty",
      "Fitness and Wellbeing",
      "Gardening and Plants",
      "Graphics and Design",
      "Health and Nutrition",
      "Home Improvement and DIY",
      "Language Learning",
      "Leadership and Management",
      "Music and Instruments",
      "Networking and Servers",
      "Personal Development",
      "Pet Training and Care",
      "Photography and Videography",
      "Programming and Tech",
      "Sustainability and Green Living",
      "Travel and Culture",
      "Video and Animation",
      "Writing and Content Creation",
    ],
    currentUserTrainerProfile: null,
    lastFetch: null,
    messageTrainerDialog: false,
    trainers: [],
    viewCertificationDialog: false,
  }),
  mutations,
  actions,
  getters,
};
